import * as types from '../mutation-types'
import UUID from '../../uuid_generator'

const state = {
  /**
   * cart_items = { _id: Number, _name: String, _price: Number, add_ons: []  }
   */
  cart_items: {
    Services: [],
    CustomCharges: [],
    Packages: []
  },
  counter: 0,
  CartID: '',
  selectedCard: '',
  checkoutAddress: ''
}

const getters = {
  get_cart_items: state => state.cart_items,
  get_counter: state => state.counter,
  getCartID: state => state.CartID,
  is_in_cart: state => (item_id, cardType) => {
    if (state.counter > 0) {
      let search_in = null
      if (cardType == 'Service') {
        search_in = state.cart_items['Services']
        if (state.cart_items['Packages']) {
          for (let i = 0; i < state.cart_items['Packages'].length; i++) {
            let item = state.cart_items['Packages'][i].services_included.findIndex(service => service._id === item_id)
            if (item >= 0) {
              return true
            }
          }
        }
      } else {
        search_in = state.cart_items['Packages']
      }
      const item = search_in.findIndex(item => (item._id === item_id && item.cardType == cardType))
      if (item >= 0) {
        return true
      } else {
        return false
      }

    }
  },
  get_from_cart: state => (item_id, cardType) => {
    if (state.counter > 0) {
      let search_in = null
      if (cardType == 'Service') {
        search_in = state.cart_items['Services']
        if (state.cart_items['Packages']) {
          for (let i = 0; i < state.cart_items['Packages'].length; i++) {
            let item = state.cart_items['Packages'][i].services_included.findIndex(service => service._id === item_id)
            if (item >= 0) {
              return {
                package: state.cart_items['Packages'][i],
                index: item
              }
            }
          }
        }
      } else {
        search_in = state.cart_items['Packages']
      }
      const item = search_in.find(item => (item._id === item_id && item.cardType == cardType))
      if (item) {
        return item
      } else {
        return false
      }

    }
  },
  total_cart: (state, getters) => () => {
    const total_services = state.cart_items['Services'].reduce((total, curr) =>
      total + +curr._price + (curr.add_ons ? curr.add_ons.reduce((t, c) => t + +c.addon.addon_price, 0) : 0), 0)
    const total_custom_charges = state.cart_items['CustomCharges'].reduce((total, curr) =>
      total + +curr._price, 0)
    const total_packages = state.cart_items['Packages'].reduce((total, curr) =>
      total + +curr._price + (curr.services_included ? curr.services_included.reduce((total, curr) =>
        total + (curr.add_ons ? curr.add_ons.reduce((t, c) => t + +c.addon.addon_price, 0) : 0), 0) : 0), 0)

    return total_services + total_custom_charges + total_packages
  }
}

const actions = {
  add_to_cart({
    commit
  }, cart_item) {
    commit(types.ADD_TO_CART, cart_item)
  },
  remove_from_cart({
    commit
  }, payload) {
    commit(types.REMOVE_FROM_CART, payload)
  },
  remove_from_cart_addon({
    commit
  }, payload) {
    commit(types.REMOVE_FROM_CART_ADDON, payload)
  },
  remove_from_cart_packaged_addon({
    commit
  }, payload) {
    commit(types.REMOVE_FROM_CART_PACKAGED_ADDON, payload)
  },
  clear_cart({
    commit
  }) {
    commit(types.CLEAR_CART)
  },
  saveCartID({
    commit
  }) {
    commit(types.SAVE_CARTID, UUID.generate())
  },
}

const mutations = {
  [types.ADD_TO_CART](state, cart_item) {
    // console.log(cart_item)
    if (cart_item.cardType === 'Service') {
      state.cart_items['Services'].push(cart_item)
    } else if (cart_item.cardType === 'CustomCharge') {
      state.cart_items['CustomCharges'].push(cart_item)
    } else {
      state.cart_items['Packages'].push(cart_item)
    }
    // state.cart_items.push(cart_item)
    state.counter += 1
  },
  [types.REMOVE_FROM_CART](state, payload) {
    let search_in, item_id
    if (payload.cardType == 'Service') {
      search_in = state.cart_items['Services']
      item_id = payload._id
    } else if (payload.cardType == 'CustomCharge') {
      search_in = state.cart_items['CustomCharges']
      item_id = payload._id
    } else {
      search_in = state.cart_items['Packages']
      item_id = payload.package_id
    }
    if(payload.cardType === 'Package') {
      const remove_item = search_in.findIndex(item => (item.package_id === item_id && item.cardType === payload.cardType))
      if (remove_item >= 0) {
        search_in.splice(remove_item, 1)
        state.counter -= 1
        return
      }
    } else {
      const remove_item = search_in.findIndex(item => (item._id === item_id && item.cardType === payload.cardType))
      if (remove_item >= 0) {
        search_in.splice(remove_item, 1)
        state.counter -= 1
      }
    }
  },
  [types.UPDATE_CART_ITEM_ADDONS] (state, payload) {
    let cartItem
    if (state.counter > 0) {
      if (state.cart_items['Packages']) {
        for (let pkg of state.cart_items['Packages']) {
          cartItem = pkg.services_included.find(service => service._id === payload.id)
          if (cartItem) break
        }
      }
      if (!cartItem) {
        cartItem = state.cart_items['Services'].find(service => service._id === payload.id)
      }
    }
    if (cartItem) cartItem.add_ons = payload.add_ons
  },
  [types.REMOVE_FROM_CART_ADDON](state, payload) {
    const _item = state.cart_items['Services'].find(item => (item._id === payload._id && item.cardType === payload.cardType))
    const remove_item = _item.add_ons.findIndex(addon => addon.addon.addon_id === payload.addon_id)
    if (remove_item >= 0) {
      _item.add_ons.splice(remove_item, 1)
      delete _item.selected_addons[`selected_${payload.addon_id}_1`]
    }
  },
  [types.REMOVE_FROM_CART_PACKAGED_ADDON](state, payload) {
    state.cart_items['Packages'].forEach((element) => {
      if (element._id === payload.package_id) {
        const item_index = element.services_included.findIndex(item => (item._id === payload.service_id))
        const _item = element.services_included[item_index]
        const remove_item = _item.add_ons.findIndex(addon => addon.addon.addon_id === payload.addon_id)
        if (remove_item >= 0) {
          _item.add_ons.splice(remove_item, 1)
          delete _item.selected_addons[`selected_${payload.addon_id}_${item_index + 1}`]
        }
      }
    });
  },
  [types.CLEAR_CART](state) {
    state.cart_items = {
      Services: [],
      CustomCharges: [],
      Packages: []
    }
    state.counter = 0
  },
  [types.SAVE_CARTID](state, payload) {
    state.CartID = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
