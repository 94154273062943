import Vue from 'vue';
import * as types from '../mutation-types';
import {
  API_V1_ROOT,
  WEB_ROOT
} from '../../config';

const timePickerRegexp = /(\d{1,2}):(\d{2})(a|pm)/;
const datePickerRegexp = /(\d{4})-(\d{2})-(\d{2})/;

const state = {};

const getters = {};

const actions = {
  generateTimeString(context, payload) { //parameter is an object {hours: integer, minutes: integer}
    let {
      hours,
      minutes
    } = payload;
    let suffix = '';
    if (hours < 12) {
      hours = hours === 0 ? 12 : hours;
      suffix = 'am';
    } else {
      hours = hours === 12 ? hours : (hours - 12);
      suffix = 'pm';
    }
    let timeString = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${suffix}`;
    return timeString;
  },
  getHourFromTimeString(context, timeString) {
    let timeInfo = timePickerRegexp.exec(timeString);
    let hour = '';
    if (+timeInfo[1] === 12) {
      hour = timeInfo[3] === 'am' ? 0 : 12;
    } else {
      hour = timeInfo[3] === 'am' ? +timeInfo[1] : +timeInfo[1] + 12;
    }
    return hour;
  },
  getMinutesFromTimeString(context, timeString) {
    let timeInfo = timePickerRegexp.exec(timeString),
      minutes;
    minutes = +timeInfo[2];
    return minutes;
  },
  createTimeBlock(context, payload) {
    //payload is an object with two properties, startTimeString and endTimeStringOrLength.
    //They are strings generated by the Vuetify timepicker.
    //Optionally, endTimeStringOrLength may be an integer representing length in
    //minutes.
    let {
      startTimeString,
      endTimeStringOrLength
    } = payload;
    let timeBlock = {};
    timeBlock.startTimeString = startTimeString;
    timeBlock.endTimeStringOrLength = endTimeStringOrLength;
    let startTimeInfo = timePickerRegexp.exec(startTimeString);
    if (+startTimeInfo[1] === 12) {
      timeBlock.startHours = startTimeInfo[3] === 'am' ? 0 : 12;
    } else {
      timeBlock.startHours = startTimeInfo[3] === 'am' ? +startTimeInfo[1] : +startTimeInfo[1] + 12;
    }
    timeBlock.startMinutes = +startTimeInfo[2];
    if (typeof endTimeStringOrLength === 'string') {
      let endTimeInfo = timePickerRegexp.exec(endTimeStringOrLength);
      if (+endTimeInfo === 12) {
        timeBlock.endHours = endTimeInfo[3] === 'am' ? 0 : 12;
      } else {
        timeBlock.endHours = endTimeInfo[3] === 'am' ? +endTimeInfo[1] : +endTimeInfo[1] + 12;
      }
      timeBlock.endMinutes = +endTimeInfo[2];
    } else if (typeof endTimeStringOrLength === 'number') {
      calculatedEndTime = timeBlock.startHours + (endTimeStringOrLength + timeBlock.startMinutes) / 60;
      if (calculatedEndHours > 24) {
        return false; //overnight time blocks are not supported
      }
      timeBlock.endHours = timeBlock.startHours + Math.floor((endTimeStringOrLength + timeBlock.startMinutes) / 60);
      timeBlock.endMinutes = (timeBlock.startMinutes + endTimeStringOrLength) % 60;
    } else {
      console.log('endTimeStringOrLength is neither a string or a number');
    }
    if (typeof timeBlock.endTimeStringOrLength === 'number') {
      timeBlock.length = timeBlock.endTimeStringOrLength;
    } else {
      timeBlock.length = (timeBlock.endHours * 60 + timeBlock.endMinutes) - (timeBlock.startHours * 60 + timeBlock.startMinutes);
    }
    if ((timeBlock.startHours + timeBlock.startMinutes / 60) < (timeBlock.endHours + timeBlock.endMinutes / 60)) {
      return timeBlock;
    } else return false;
  },
  timeBlockConflict(context, payload) {
    //payload is an object with two time block objects
    //created using the createTimeBlock action.
    let {
      timeBlock1,
      timeBlock2
    } = payload;
    if (((timeBlock1.startHours + this.startMinutes / 60) > (timeBlock2.endHours + timeBlock2.endMinutes / 60)) ||
      ((timeBlock1.endHours + this.endMinutes / 60) < (timeBlock2.startHours + timeBlock2.endHours / 60))) {
      return false;
    } else {
      return true;
    }
  },
  timeBlockIncludes(context, payload) {
    //payload is an object with two time block objects
    //created using the createTimeBlock action.
    //checks if TimeBlock 1 includes TimeBlock 2
    let {
      timeBlock1,
      timeBlock2
    } = payload;
    if (((timeBlock1.startHours + timeBlock1.startMinutes / 60) <= (timeBlock2.startHours + timeBlock2.startMinutes / 60)) &&
      ((timeBlock1.endHours + timeBlock1.endMinutes / 60) >= (timeBlock2.endHours + timeBlock2.endMinutes / 60))) {
      return true;
    } else {
      return false;
    }
  },
  timeBlockMerge(context, payload) {
    //payload is an object with two time block objects
    //created using the createTimeBlock action.
    let {
      timeBlock1,
      timeBlock2
    } = payload;
    if (!context.dispatch('timeBlockConflict', payload)) {
      console.log('Attempted to merge non-conflicting TimeBlock objects');
      return false;
    }
    let startTimeString, endTimeString;
    if ((timeBlock1.startHours + timeBlock1.startMinutes / 60) < (timeBlock2.startHours + timeBlock2.startMinutes / 60)) {
      startTimeString = timeBlock1.startTimeString;
    } else {
      startTimeString = timeBlock2.startTimeString;
    }
    if ((timeBlock1.endHours + timeBlock1.endMinutes / 60) > (timeBlock2.endHours + timeBlock2.endHours / 60)) {
      endTimeString = context.dispatch('generateTimeString', {
        hours: timeBlock1.endHours,
        minutes: timeBlock1.endMinutes
      });
    } else {
      endTimeString = context.dispatch('generateTimeString', {
        hours: timeBlock2.endHours,
        minutes: timeBlock2.endMinutes
      });
    }
    return context.dispatch('createTimeBlock', {
      startTimeString,
      endTimeStringOrLength: endTimeString
    });
  },
  addTimeBlockToArray(context, payload) {
    //payload is an object with a time block object and an array
    let {
      newTimeBlock,
      timeBlockArray
    } = payload;
    if (timeBlockArray.some(timeBlock => context.dispatch('timeBlockConflict', {
        timeBlock1: timeBlock,
        timeBlock2: newTimeBlock
      }))) {
      return false;
    } else {
      timeBlockArray.push(newTimeBlock);
      timeBlockArray.sort(function (timeBlock1, timeBlock2) {
        if ((timeBlock1.startHours + timeBlock1.startMinutes / 60) < (timeBlock2.startHours + timeBlock2.startMinutes / 60)) return -1;
        else return 1;
      });
      return true;
    }
  },
  parseDateString(context, dateString) {
    let [year, month, day] = dateString.split('-').map(element => +element);
    month -= 1;
    return [year, month, day];
  },
  createAppointment(context, payload) {
    //payload has five parameters:
    //first parameter is id of service or custom charge, second parameter is a
    //string, either "Service" or "CustomCharge", third parameter is a string
    //generated by Vuetify datepicker, the fourth parameter is a string
    //generated by Vuetify timepicker, the last parameter is an integer that
    //represents minutes
    let {
      attachedItemID,
      attachedItemType,
      appointmentDateString,
      appointmentStartTimeString,
      appointmentLength
    } = payload;
    let appointment = context.dispatch('createTimeBlock', {
      appointmentStartTimeString,
      appointmentLength
    });
    appointment.attachedItemID = attachedItemID;
    appointment.attachedItemType = attachedItemType;
    appointment.dateString = appointmentDateString;
    return appointment;
  },
  isPresentOrFuture(context, dateString) {
    let today = new Date();
    let [year, month, date] = parseDateString(dateString);
    if (year > today.getFullYear()) return true;
    else if (year === today.getFullYear()) {
      if (month > today.getMonth()) return true;
      else if (month === today.getMonth()) {
        if (date >= today.getDate()) return true;
      }
    }
    return false;
  },
  saveScheduleToServer(context, payload) {
    const options = {};
    let formData = new FormData();
    let jsonData = JSON.stringify(payload);
    formData.append('data', jsonData)
    return Vue.http.post(`${API_V1_ROOT}save_employee_schedule/`, formData, options);
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
