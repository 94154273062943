import {clone} from 'lodash'

export const ROLE_PAGE_ID_SUPPLIERS = 'SUPPLIERS'
export const ROLE_PAGE_ID_TEAM_MEMBERS = 'TEAM_MEMBERS'
export const ROLE_PAGE_ID_BROKERAGES = 'BROKERAGES'
export const ROLE_PAGE_ID_AGENTS = 'AGENTS'
export const ROLE_PAGE_ID_SHOP = 'SHOP'
export const ROLE_PAGE_ID_ORDERS = 'ORDERS'
export const ROLE_PAGE_ID_BILLING = 'BILLING'
export const ROLE_PAGE_ID_FINANCING = 'FINANCING'
export const ROLE_PAGE_ID_TRIPS = 'TRIPS'
export const ROLE_PAGE_ID_INVENTORY = 'INVENTORY'
export const ROLE_PAGE_ID_SETTINGS = 'SETTINGS'

export const OPERATIONS_DEFAULT = {
  add: false,
  edit: false,
  delete: false
}

export const ROLE_PAGE_PERMISSIONS = [
  { name: 'Suppliers', value: false, id: ROLE_PAGE_ID_SUPPLIERS, operations: clone(OPERATIONS_DEFAULT) },
  { name: 'Team Members', value: true, id: ROLE_PAGE_ID_TEAM_MEMBERS, operations: clone(OPERATIONS_DEFAULT) },
  { name: 'Brokerages', value: false, id: ROLE_PAGE_ID_BROKERAGES, operations: clone(OPERATIONS_DEFAULT) },
  { name: 'Agents', value: false, id: ROLE_PAGE_ID_AGENTS, operations: clone(OPERATIONS_DEFAULT) },
  { name: 'Shop', value: true, id: ROLE_PAGE_ID_SHOP, operations: clone(OPERATIONS_DEFAULT) },
  { name: 'Orders', value: false, id: ROLE_PAGE_ID_ORDERS, operations: clone(OPERATIONS_DEFAULT) },
  { name: 'Billing', value: false, id: ROLE_PAGE_ID_BILLING, operations: clone(OPERATIONS_DEFAULT) },
  // { name: 'Financing', value: true, id: ROLE_PAGE_ID_FINANCING, operations: clone(OPERATIONS_DEFAULT) },
  { name: 'Trips', value: true, id: ROLE_PAGE_ID_TRIPS, operations: clone(OPERATIONS_DEFAULT) },
  { name: 'Inventory', value: true, id: ROLE_PAGE_ID_INVENTORY, operations: clone(OPERATIONS_DEFAULT) },
  { name: 'Settings', value: false, id: ROLE_PAGE_ID_SETTINGS, operations: clone(OPERATIONS_DEFAULT) },
]
