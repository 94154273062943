import Vue from 'vue'

import { API_V1_ROOT } from '../config'

const options = { params: {} }

export default {
  getSupplierSuppliers() {
    return Vue.http.get(`${API_V1_ROOT}user_related_suppliers/`, options);
  }
}
