export function roundNumber(num, scale) {
  if(!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale)  + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = ""
    if(+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}

export function truncate(string, numberOfLetters) {
  if (string.length <= numberOfLetters) return string
  else return string.slice(0, numberOfLetters-3) + '...'
}

export function getCardHeight(price, isXsViewport) {
  /***
   * Get card height for package or service card
   *
   * @param isXsViewport - Pass the value for vuetify breakpoint `xsOnly`
   */
  if (typeof price === 'number' && !isXsViewport) {
    return '450px'
  }
  if (typeof price === 'number' && isXsViewport) {
    return '450px'
  }
  if (typeof price !== 'number' && isXsViewport) {
    //return '360px'
    return '300px'
  }
  return '100%'
}
