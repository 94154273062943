import * as types from '../mutation-types'
import Vue from 'vue'
import {
  API_V1_ROOT
} from '../../config'

const state = {
  checkout_address: {},
  agent_cards: [],
  taxes: [],
  paymentMethodCategories: [],
  snackBar: {
    value: false,
    color: '',
    message: '',
  },
}

const getters = {
  getCheckoutAddress: state => state.checkout_address,
  getAgentCards: state => state.agent_cards,
  paymentMethodCategories: state => state.paymentMethodCategories,
  taxes: state => state.taxes,
  combinedTaxRate: state => state.taxes.reduce((total, tax) => (total + +tax.rate), 0),
  getSnackBar: state => state.snackBar
}

const actions = {
  loadAgentCards({
    commit,
    getters,
    rootState
  }, payload) {
    /**
     * This is POST method which is link to same URL. to fetch the saved cards for agent.
     */
    const options = {
      headers: {
        'Content-type': 'application/json'
      }
    }
    const data = {}
    data.not_checkout = payload.notCheckout ? true : false
    if (!data.not_checkout) { // checkout needs the cart and the ordering for information for advanced permissions...
      data.cart = rootState.cart.cart_items
      if (getters.getSelectedAgent && getters.getSelectedAgent.id) {
        data.ordering_for = getters.getSelectedAgent.id
      }
      if(rootState.home.paidBy) data.paid_by = rootState.home.paidBy
      if(rootState.home.selectedBrokerage) data.ordering_for_brokerage = rootState.home.selectedBrokerage.id
    }
    if (payload.agent_id) data.ordering_for = payload.agent_id
    if (payload.brokerage_id) data.ordering_for_brokerage = payload.brokerage_id
    Vue.http.post(`${API_V1_ROOT}load_agent_cards/`, data, options)
      .then((response) => {
        console.log('AGENT CARDS', response)
        if (!response.data.error)
          commit(types.SAVE_AGENT_CARDS, response.data.payment_methods)
          commit(types.SAVE_PAYMENT_METHOD_CATEGORIES, response.data.payment_method_categories)
      })
      .catch((error) => {
        payload.snackbarThis.snackbar = {
            value: true,
            color: 'warning',
            message: 'Supplier and admin cannot order for themselves, or for brokerage without agent'
          }
      })
  },
  deleteAgentCard({
    commit
  }, payload) {
    commit(types.DELETE_AGENT_CARDS, payload)
  },
  async saveOrder({
    getters,
    dispatch
  }, payload) {
    const options = {}
    let order = {
      'address': payload.address,
      'contact': payload.contact_info,
      'payment_card_id': payload.payment_card_id,
      'total': payload.total
    }
    if (getters.getSelectedAgent && getters.getSelectedAgent.id) {
      Object.assign(order, {
        'ordering_for': getters.getSelectedAgent.id
      })
    }
    let formData = new FormData();
    let jsonorder = JSON.stringify(order)
    formData.append('order', jsonorder)
    const services_data = getters.get_cart_items['Services']
    const custom_charges_data = getters.get_cart_items['CustomCharges']
    const packages_data = getters.get_cart_items['Packages']

    if (services_data.length)
      formData.append('services', JSON.stringify(services_data))
    if (custom_charges_data.length)
      formData.append('custom_charges', JSON.stringify(custom_charges_data))
    if (packages_data.length)
      formData.append('packages', JSON.stringify(packages_data))

    await Vue.http.post(`${API_V1_ROOT}save_order/`, formData, options)
      .then((response) => {
        if (response.data.error) {
          if (response.data.desc.message) {
            alert(`Unable to process your order. ${response.data.desc.message}`)
          } else {
            alert('Unable to process your order. Please refresh, and if the problem persists then contact us.')
          }
        } else {
          dispatch('clear_cart')
        }
      })
      .catch((error) => {
        alert('Unable to process your order. Please refresh, and if the problem persists then contact us.')
        return error
      })
  },
  setSnackBar({commit}, payload) {
    commit('setSnackBarDetails', payload);
  }
}

const mutations = {
  [types.SAVE_AGENT_CARDS](state, payload) {
    if (payload.append)
      state.agent_cards.push(payload.newCard)
    else
      state.agent_cards = payload
  },
  [types.DELETE_AGENT_CARDS](state, payload) {
    const card_index = state.agent_cards.findIndex(item => item.card_id === payload)
    if (card_index > -1) {
      state.agent_cards.splice(card_index, 1)
    }
    //state.agent_cards = payload
  },
  [types.UPDATE_AGENT_CARD](state, payload) {
    const cardToUpdate = state.agent_cards.find(item => item.card_id === payload.card_id)
    Object.assign(cardToUpdate, payload)
  },
  [types.SAVE_PAYMENT_METHOD_CATEGORIES](state, payload) {
    state.paymentMethodCategories = payload
  },
  [types.SAVE_TAXES](state, payload) {
    state.taxes = payload
  },
  setSnackBarDetails(state, payload) {
    state.snackBar.value = payload.value;
    state.snackBar.color = payload.color;
    state.snackBar.message = payload.message;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
