import Vue from 'vue'
import {
  API_V1_ROOT
} from '../../config'
import * as types from '../mutation-types'

const state = {
  services: [],
  packages: [],
  loading: 0,
  selected_cat: null,
  package_selected_cat: null,
  sub_category_data: [],
  services_data_only: [],
  custom_charge_dialog: false,
  supplier_notes: [],
  // selected_sub_cat: {}
}

const getters = {
  allServices: state => state.services,
  allPackages: state => state.packages,
  loading: state => state.loading,
  selected_cat: state => state.selected_cat,
  package_selected_cat: state => state.package_selected_cat,
  get_sub_category_data_only: state => state.sub_category_data,
  get_services_data_only: state => state.services_data_only,
  custom_charge_dialog: state => state.custom_charge_dialog,
  supplier_notes: state => state.supplier_notes,

}

const actions = {
  getAllServices({
    commit,
    state,
    getters
  }, payload) {

    const options = {
      before: function (request) {
        commit(types.SETLOADING, true)
      },
      params: {
        city: payload.city
      },
    }
    if (payload.selectedAgent && payload.selectedAgent.id) {
      Object.assign(options.params, {
        ordering_for: payload.selectedAgent.id
      })
    }

    Vue.http.get(`${API_V1_ROOT}service_categories/`, options)
      .then((response) => {
        // console.log(response.data)
        // response.data.map(item => item.sort((a, b) => a.sort_order - b.sort_order))
        commit(types.SETLOADING, false)
        commit(types.RECEIVE_SERVICES, {
          data: response.data.services.sort((a, b) => a.sort_order - b.sort_order)
        })
        commit(types.SET_SUPPLIER_NOTES, response.data.supplier_notes)
      })
      .catch((error) => {
        commit(types.SETLOADING, false)
        alert('Unable to load data. Please contact system admin. ')
        location.reload()
      })
  },
  getAllPackages({
    commit
  }, user_city) {
    const options = {
      before: function (request) {
        commit(types.SETLOADING, true)
      },
      params: {
        city: user_city
      }
    }
    Vue.http.get(`${API_V1_ROOT}package_categories/`, options)
      .then((response) => {
        commit(types.SETLOADING, false)
        commit(types.RECEIVE_PACKAGES, {
          data: response.data
        })

      })
      .catch((error) => {
        //Promise.reject(error)
        return false
      })
  }
}

const mutations = {
  [types.RECEIVE_SERVICES](state, payload) {
    state.services = payload.data
    // This is just to extract all the servies and save them into 1 array.
    // So that later we can get them just by passing service id
    let service_data = []
    for (let i = 0; i < payload.data.length; i++) {
      for (let j = 0; j < payload.data[i].sub_categories.length; j++) {
        state.sub_category_data.push(payload.data[i].sub_categories[j])
        service_data.push(payload.data[i].sub_categories[j].service_data)
      }
    }
    state.services_data_only = [].concat.apply([], service_data);

  },
  [types.RECEIVE_PACKAGES](state, payload) {
    state.packages = payload.data
  },
  [types.SET_SELECTED_CAT](state, payload) {
    state.selected_cat = payload
  },
  [types.SET_CUSTOM_CHARGE_DIALOG_VISIBILITY](state, payload) {
    state.custom_charge_dialog = payload
  },
  [types.SET_SELECTED_CAT_PACKAGES](state, payload) {
    state.package_selected_cat = payload
  },
  [types.SETLOADING](state, payload) {
    if (payload == true) {
      state.loading += 1
    } else {
      state.loading -= 1
    }
  },
  [types.SET_SUPPLIER_NOTES](state, payload) {
    state.supplier_notes = payload
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
