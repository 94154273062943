<template>
  <v-app class="white">
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  mounted() {
    this.$store.dispatch('isTokenValidStore', {router: this.$router})
    // let hubSpotScript = document.createElement('script')
    // hubSpotScript.async = true
    // hubSpotScript.setAttribute('src', '//js.hs-scripts.com/6864797.js')
    // document.head.appendChild(hubSpotScript)
  }
}
</script>

<style lang="stylus">
    @import './stylus/main'
</style>
