import * as types from '../mutation-types'

const state = {
  upload_images_dialog: false,
  page_title: '',
  change_IPV_dialog: false,
  change_inventory_category_dialog: false,
}

const getters = {
  getUploadImagesDialog: state => state.upload_images_dialog,
  getPageTitle: state => state.page_title,
  getChangeIPVDialog: state => state.change_IPV_dialog,
  getChangeInventoryCategoryDialog: state => state.change_inventory_category_dialog,
}

const actions = {
  toggleUploadImagesDialog({
    commit
  }, visiblity) {
    commit(types.IMAGES_UPLOAD_DIALOG_VISIBILITY, visiblity)
  },
  toggleChangeIPVDialog({
    commit
  }, visiblity) {
    commit(types.INVENTORY_PV_DIALOG, visiblity)
  },
  toggleChangeInventoryCategoryDialog({
    commit
  }, visiblity) {
    commit(types.INVENTORY_CATEGORY_DIALOG, visiblity)
  },
  savePageTitle({
    commit
  }, title) {
    commit(types.PAGE_TITLE, title)
  },
}

const mutations = {
  [types.IMAGES_UPLOAD_DIALOG_VISIBILITY](state, payload) {
    state.upload_images_dialog = payload
  },
  [types.PAGE_TITLE](state, payload) {
    state.page_title = payload
  },
  [types.INVENTORY_PV_DIALOG](state, payload) {
    state.change_IPV_dialog = payload
  },
  [types.INVENTORY_CATEGORY_DIALOG](state, payload) {
    state.change_inventory_category_dialog = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
