import * as types from '../mutation-types'

const state = {
  target_id: '',
  target_name: '',
  viewer_id: '',
  viewer_name: '',
}

const getters = {
  target_id: state => state.target_id,
  target_name: state => state.target_name,
  viewer_id: state => state.viewer_id,
  viewer_name: state => state.viewer_name,
}

const actions = {}

const mutations = {
  [types.SET_TARGET_USER](state, payload) {
    state.target_id = payload.id
    state.target_name = payload.name
  },
  [types.SET_VIEWING_USER](state, payload) {
    state.viewer_id = payload.id
    state.viewer_name = payload.name
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}