import Vue from 'vue'
import Vuex from 'vuex'

import * as actions from './actions'
import * as getters from './getters'

import cart from './modules/cart'
import catalog from './modules/catalog'
import home from './modules/home'
import accounts from './modules/accounts'
import invoices from './modules/invoices'
import optionsdialog from './modules/optionsdialog'
import checkout from './modules/checkout'
import myorders from './modules/myorders'
import multi_use from './modules/multi_use'
import calendar from './modules/calendar'
import attachments from './modules/attachments'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  actions,
  getters,
  modules: {
    catalog,
    home,
    cart,
    accounts,
    invoices,
    optionsdialog,
    checkout,
    myorders,
    multi_use,
    calendar,
    attachments,
  }
})

export default store
