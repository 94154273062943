import Vue from 'vue'
import { API_V1_ROOT } from '../../config'
import * as types from '../mutation-types'
import { get, find } from 'lodash'

import EmployeesService from '../../services/employeesService'
import SuppliersService from '../../services/suppliersService'

const state = {
  token: '',
  login_dialog: false,
  signup_dialog: false,
  reset_password_dialog: false,
  terms_dialog: false,
  dasboard_dialog: false,
  name: {
    first_name: 'Guest',
    last_name: 'Guest',
    admin_status: false,
    user_id: '',
    user_type: 'guestuser',
    agreed_to_terms: false,
    suppliers: [],
    brokerages: [],
    role: null
  },
  related: {
    employees: [],
    suppliers: [],
  },
  login_error: false,
  reset_password_error: false,
  login_snackbar: {
    display: false,
    snackbarText: '',
    snackbarColor: '',
  },
}

const getters = {
  getToken: state => state.token,
  login_dialog: state => state.login_dialog,
  signup_dialog: state => state.signup_dialog,
  reset_password_dialog: state => state.reset_password_dialog,
  terms_dialog: state => state.terms_dialog,
  first_name: state => state.name.first_name,
  last_name: state => state.name.last_name,
  admin_status: state => state.name.admin_status,
  user_id: state => state.name.user_id,
  user_type: state => state.name.user_type,
  agreed_to_terms: state => state.name.agreed_to_terms,

  getRole: state => state.name.role,

  suppliers: state => state.name.suppliers,
  getSuppliers: state => state.name.suppliers,

  relatedSuppliers: state => state.related.suppliers,
  supplier_employees: state => state.related.employees,
  brokerages: state => state.name.brokerages,

  login_error: state => state.login_error,
  reset_password_error: state => state.reset_password_error,
  snackbar: state => state.login_snackbar,
  snackbarText: state => state.login_snackbar.snackbarText,
  snackbarColor: state => state.login_snackbar.snackbarColor,
  dasboard_dialog: state => state.dasboard_dialog,
}

const actions = {
  toggleLoginError({
    commit
  }, payload) {
    commit(types.LOGIN_ERROR, payload)
  },
  toggleResetPasswordError({
    commit
  }, payload) {
    commit(types.RESET_PASSWORD_ERROR, payload)
  },
  showSnackbar({
    commit
  }, payload) {
    commit(types.LOGIN_SNACKBAR, payload)
  },
  saveToken({
    commit
  }, payload) {
    commit(types.SETTOKEN, payload)
  },
  saveName({
    commit
  }, name) {
    commit(types.SAVE_NAME, name)
  },
  logout({
    state,
    dispatch
  }) {
    var formData = new FormData();
    formData.append('client_id', atob(window.meta_data).split(':')[0])
    formData.append('token', JSON.parse(localStorage.token).token)
    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }
    Vue.http.post(`${API_V1_ROOT}revoke_token/`, formData, options)
      .then((response) => {
        localStorage.clear()
        location.href = '/'
      })
      .catch((error) => {
        console.log.bind(console)
      })
  },
  hideLoginDialog({
    commit
  }) {
    commit(types.LOGIN_DIALOG, false)
  },
  showLoginDialog({
    commit
  }) {
    commit(types.LOGIN_DIALOG, true)
  },
  hideResetPasswordDialog({
    commit
  }) {
    commit(types.RESET_PASSWORD_DIALOG, false)
  },

  loginViewStore({
    state,
    commit,
    dispatch,
    getters
  }, payload) {
    var formData = new FormData();
    formData.append('grant_type', 'password')
    formData.append('username', payload.email)
    formData.append('password', payload.password)
    const options = {
      headers: {
        'Authorization': `Basic ${window.meta_data}`
      }
    }
    Vue.http.post(`${API_V1_ROOT}token/`, formData, options)
      .then((response) => {
        const token = response.data.access_token
        if (token) {
          const token_data = {
            token: response.data.access_token,
            _token: response.data.refresh_token,
          }
          dispatch('saveToken', token_data)
          dispatch('hideLoginDialog')
          dispatch('showSnackbar', {
            display: true,
            snackbarColor: 'success',
            snackbarText: 'You have logged in successfully.'
          })
          dispatch('isTokenValidStore')
          dispatch('fetchSupplierEmployees')
        } else {
          dispatch('toggleLoginError', true)
        }
      })
      .catch((error) => {
        dispatch('toggleLoginError', true)
      })
  },

  resetPasswordViewStore({
    state,
    commit,
    dispatch,
    getters
  }, payload) {
    var formData = new FormData();
    formData.append('email', payload.email)
    const options = {
      headers: {
        'Authorization': `Basic ${window.meta_data}`
      }
    }
    Vue.http.post(`${API_V1_ROOT}password_reset/`, formData, options)
      .then((response) => {
        dispatch('hideResetPasswordDialog')
        dispatch('showLoginDialog')
        dispatch('showSnackbar', {
          display: true,
          snackbarColor: 'success',
          snackbarText: 'We have sent you an e-mail. Please contact us if you do not receive it within a few minutes.'
        })
      })
      .catch((error) => {
        dispatch('hideResetPasswordDialog')
        dispatch('showLoginDialog')
        dispatch('toggleResetPasswordError', true)
        dispatch('showSnackbar', {
          display: true,
          snackbarColor: 'red',
          snackbarText: 'There was an error during password reset. Please try again later.'
        })
      })
  },
  // Token checking is handled by axios interceptors in main.js. The main function of this is just to get the necessary data now.
  // The more you code, the more you learn ;)
  isTokenValidStore({
    state,
    commit,
    dispatch
  }, payload) {
    Vue.http.get(`${API_V1_ROOT}is_valid_token/`)
      .then((response) => {
        if (!response) return
        dispatch('saveName', response.data);
        if (!state.name.agreed_to_terms) commit(types.TERMS_DIALOG, true)

        // Check for employee permission for current route
        const currentRoute = payload.router.history.current
        const userRole = response.data.role

        if (response.data.user_type === 'employeeuser' && !userRole) {
          // Redirect user to homepage if they are an employeeuser and arre not assigned a role yet.
          next('/')
        } else {
          if (userRole) {
            const permissions = get(userRole, 'permissions', null);
            const routePermId = get(currentRoute, 'meta.permission_id', null)

            if (routePermId) {
              const userPermForRoute = find(permissions, perm => get(perm, 'id', null) === routePermId)
              if (userPermForRoute && userPermForRoute.value === false || !permissions) {
                // Redirect employee back to Home, because they don't have permission to view this page
                payload.router.push('/')
              }
            }
          }
        }

      })

  },
  fetchSupplierEmployees({
    state,
    commit,
    dispatch
  }, payload) {
    EmployeesService.getSupplierEmployees()
      .then(response => {
        dispatch('saveSupplierEmployees', response.data)
      })
      .catch(response => {
        dispatch('saveSupplierEmployees', [])
      })
  },
  saveSupplierEmployees({
    commit
  }, employees) {
    commit(types.SAVE_SUPPLIER_EMPLOYEES, employees)
  },
  fetchRelatedSuppliers({
    dispatch
  }, payload) {
    SuppliersService.getSupplierSuppliers()
      .then(response => {
        dispatch('saveRelatedSuppliers', response.data)
      })
      .catch(response => {
        dispatch('saveRelatedSuppliers', [])
      })
  },
  saveRelatedSuppliers({
    commit
  }, suppliers) {
    commit(types.SAVE_RELATED_SUPPLIERS, suppliers)
  }
}


const mutations = {
  [types.SAVE_RELATED_SUPPLIERS](state, payload) {
    state.related.suppliers = payload
  },
  [types.SAVE_SUPPLIER_EMPLOYEES](state, payload) {
    state.related.employees = payload
  },
  [types.SETTOKEN](state, payload) {
    state.token = payload
    localStorage.setItem("token", JSON.stringify(payload))
  },
  [types.LOGIN_DIALOG](state, payload) {
    state.login_dialog = payload
  },
  [types.SIGNUP_DIALOG](state, payload) {
    state.signup_dialog = payload
  },
  [types.RESET_PASSWORD_DIALOG](state, payload) {
    state.reset_password_dialog = payload
  },
  [types.TERMS_DIALOG](state, payload) {
    state.terms_dialog = payload
  },
  [types.AGREE_TO_TERMS](state) {
    state.name.agreed_to_terms = true
  },
  [types.SAVE_NAME](state, payload) {
    state.name = payload
    state.data_loaded = true
  },
  [types.SAVE_EMPLOYEE_SCHEDULE](state, payload) {
    state.related.employees.filter(employee => employee.id === payload.id)[0].schedule_data = payload.schedule;
  },
  [types.LOGIN_ERROR](state, payload) {
    state.login_error = payload
  },
  [types.RESET_PASSWORD_ERROR](state, payload) {
    state.reset_password_error = payload
  },
  [types.LOGIN_SNACKBAR](state, payload) {
    if (typeof payload === "object") {
      state.login_snackbar = payload
    } else if (typeof payload === "boolean") {
      state.login_snackbar.display = payload
    }
  },

}

export default {
  state,
  getters,
  actions,
  mutations
}
