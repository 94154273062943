export const RECEIVE_SERVICES = 'RECEIVE_SERVICES'
export const RECEIVE_PACKAGES = 'RECEIVE_PACKAGES'
export const SAVE_ADDRESS = 'SAVE_ADDRESS'
export const LEFT_DRAWER_TOGGLE = 'LEFT_DRAWER_TOGGLE'
export const ADD_TO_CART = 'ADD_TO_CART'
export const UPDATE_CART_ITEM_ADDONS = 'UPDATE_CART_ITEM_ADDONS'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const REMOVE_FROM_CART_ADDON = 'REMOVE_FROM_CART_ADDON'
export const REMOVE_FROM_CART_PACKAGED_ADDON = 'REMOVE_FROM_CART_PACKAGED_ADDON'
export const CLEAR_CART = 'CLEAR_CART'
export const SET_CUSTOM_CHARGE_DIALOG_VISIBILITY = 'SET_CUSTOM_CHARGE_DIALOG_VISIBILITY'
export const SERVICE = 'SERVICE'
export const PACKAGE = 'PACKAGE'
export const SETLOADING = 'SETLOADING'
export const SETTOKEN = 'SETTOKEN'
export const SET_SELECTED_CAT = 'SET_SELECTED_CAT'
export const SET_SELECTED_CAT_PACKAGES = 'SET_SELECTED_CAT_PACKAGES'
export const LOGIN_DIALOG = 'LOGIN_DIALOG'
export const RESET_PASSWORD_DIALOG = 'RESET_PASSWORD_DIALOG'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const LOGIN_SNACKBAR = 'LOGIN_SNACKBAR'
export const SIGNUP_DIALOG = 'SIGNUP_DIALOG'
export const TERMS_DIALOG = 'TERMS_DIALOG'
export const AGREE_TO_TERMS = 'AGREE_TO_TERMS'
export const SAVE_BROKERAGES = 'SAVE_BROKERAGES'
export const SET_SUPPLIERS = 'SET_SUPPLIERS'
export const SAVE_CARTID = 'SAVE_CARTID'
export const SAVE_NAME = 'SAVE_NAME'
export const SAVE_EMPLOYEE_SCHEDULE = 'SAVE_EMPLOYEE_SCHEDULE'
export const SAVE_AGENT_CARDS = 'SAVE_AGENT_CARDS'
export const DELETE_AGENT_CARDS = 'DELETE_AGENT_CARDS'
export const UPDATE_AGENT_CARD = 'UPDATE_AGENT_CARD'
export const SAVE_TAXES = 'SAVE_TAXES'
export const SAVE_PAYMENT_METHOD_CATEGORIES = 'SAVE_PAYMENT_METHOD_CATEGORIES'
export const OPTIONS_DIALOG_VISIBILITY = 'OPTIONS_DIALOG_VISIBILITY'
export const SET_NEXT_COUNTER = 'SET_NEXT_COUNTER'
export const INFO_DIALOG_VISIBILITY = 'INFO_DIALOG_VISIBILITY'
export const SAMPLE_DIALOG_VISIBILITY = 'SAMPLE_DIALOG_VISIBILITY'
export const CURRENT_CARD = 'CURRENT_CARD'
export const CURRENT_PACKAGE = 'CURRENT_PACKAGE'
export const CURRENT_SAMPLES = 'CURRENT_SAMPLES'
export const NEXT_CARDS = 'NEXT_CARDS'
export const SAVE_MY_ORDERS_DATA = 'SAVE_MY_ORDERS_DATA'
export const SAVE_SELECTED_AGENT = 'SAVE_SELECTED_AGENT'
export const SAVE_SELECTED_BROKERAGE = 'SAVE_SELECTED_BROKERAGE'
export const SAVE_SELECTED_CATEGORY = 'SAVE_SELECTED_CATEGORY'
export const SAVE_PAID_BY = 'SAVE_PAID_BY'
// export const SAVE_ORDERING_ARRAY = 'SAVE_ORDERING_ARRAY'
export const SET_SUPPLIER_NOTES = 'SET_SUPPLIER_NOTES'
export const ATTACHMENT_DIALOG = 'ATTACHMENT_DIALOG'
export const INVENTORY_PV_DIALOG = 'INVENTORY_PV_DIALOG'
export const INVENTORY_CATEGORY_DIALOG = 'INVENTORY_CATEGORY_DIALOG'
export const ORDER_STATUS_DIALOG = 'ORDER_STATUS_DIALOG'
export const AGENT_ATTACHMENT_DIALOG = 'AGENT_ATTACHMENT_DIALOG'
export const ATTACHMENT_DIALOG_ITEM = 'ATTACHMENT_DIALOG_ITEM'

export const IMAGES_UPLOAD_DIALOG_VISIBILITY = 'IMAGES_UPLOAD_DIALOG_VISIBILITY'
export const PAGE_TITLE = 'PAGE_TITLE'
export const SET_TARGET_USER = 'SET_TARGET_USER'
export const SET_VIEWING_USER = 'SET_VIEWING_USER'
export const DASHBOARD_DIALOG = 'DASHBOARD_DIALOG'

export const SHOW_SUCCESS_MSG_ON_OPTION_CLOSE = 'SHOW_SUCCESS_MSG_ON_OPTION_CLOSE'
export const SUCCESS_MSG_ON_OPTION_CLOSE = 'SUCCESS_MSG_ON_OPTION_CLOSE'

export const SAVE_SUPPLIER_EMPLOYEES = 'SAVE_SUPPLIER_EMPLOYEES'
export const SAVE_RELATED_SUPPLIERS = 'SAVE_RELATED_SUPPLIERS'

export const SAVE_CATEGORY_LIST = 'SAVE_CATEGORY_LIST'

export const SAVE_ACTIVE_APP_THEME = 'SAVE_ACTIVE_APP_THEME'

// Attachment Dialog
export const SET_DIALOG = 'SET_DIALOG'
export const SET_INDEX = 'SET_INDEX'