import * as types from '../mutation-types'
import Vue from 'vue'
import {
  API_V1_ROOT
} from '../../config'

const state = {
  address: {
    administrative_area_level_1: '',
    country: 'Canada',
    latitude: 0.00,
    locality: '',
    longitude: -0.00,
    postal_code: '',
    route: '',
    street_number: ''
  },
  brokerages: [],
  left_drawer: false,
  // orderingArray: [],
  selectedAgent: null,
  selectedBrokerage: null,
  paidBy: null,
  selectedCategory: null,
  categoryList: [],
  activeAppTheme: {}
}

const getters = {
  getAddress: state => state.address,
  getBrokerages: state => state.brokerages,
  getLeftDrawer: state => state.left_drawer,
  getSelectedAgent: state => state.selectedAgent,
  getSelectedBrokerage: state => state.selectedBrokerage,
  getPaidBy: state => state.paidBy,
  getSelectedCategory: state => state.selectedCategory,
  getCategoryList: state => state.categoryList,
  getActiveAppTheme: state => state.activeAppTheme
}

const actions = {
  saveAddress({
    commit
  }, address) {
    commit(types.SAVE_ADDRESS, address)
  },
  saveSelectedAgent({
    commit
  }, agent) {
    commit(types.SAVE_SELECTED_AGENT, agent)
  },
  saveLeftDrawer({
    commit
  }, payload) {
    commit(types.LEFT_DRAWER_TOGGLE, payload)
  },
  saveBrokerages({
    commit
  }, brokerages) {
    Vue.http
      .get(`${API_V1_ROOT}get_brokerages/`)
      .then((response) => {
        commit(types.SAVE_BROKERAGES, JSON.parse(response.data))
      })
      .catch((error) => {
        //Promise.reject(error)
      })
  },
  saveSelectedCategory({
    commit
  }, category) {
    commit(types.SAVE_SELECTED_CATEGORY, category)
  },
  saveCategoryList({
    commit
  }, categoryList) {
    commit(types.SAVE_CATEGORY_LIST, categoryList)
  },
  async fetchAndSaveActiveAppTheme({commit}, theme) {
    await Vue.http
      .get(`${API_V1_ROOT}homepage_design_settings/get-active-settings/`)
      .then((response) => {
        commit(types.SAVE_ACTIVE_APP_THEME, response.data)
      })
      .catch((error) => {
        commit(types.SAVE_ACTIVE_APP_THEME, {})
      })
  },
  clearHomeFields({commit}) {
    commit("clearData")
  },
}

const mutations = {
  [types.SAVE_ACTIVE_APP_THEME](state, payload) {
    state.activeAppTheme = payload
  },
  [types.SAVE_ADDRESS](state, payload) {
    state.address = payload
  },
  [types.SAVE_CATEGORY_LIST](state, payload) {
    state.categoryList = payload
  },
  [types.SAVE_BROKERAGES](state, payload) {
    const noBrokerageIndex = payload.findIndex(brokerage => brokerage.brokerage_name === "No Brokerage")
    if (noBrokerageIndex > 0) {
      const noBrokerage = payload.splice(noBrokerageIndex, 1)[0]
      payload.splice(0, 0, noBrokerage)
    }
    state.brokerages = payload
  },
  [types.LEFT_DRAWER_TOGGLE](state, payload) {
    state.left_drawer = payload
  },
  [types.SAVE_SELECTED_AGENT](state, payload) {
    state.selectedAgent = payload
  },
  [types.SAVE_SELECTED_BROKERAGE](state, payload) {
    state.selectedBrokerage = payload
  },
  [types.SAVE_PAID_BY](state, payload) {
    state.paidBy = payload
  },
  [types.SAVE_SELECTED_CATEGORY](state, payload) {
    state.selectedCategory = payload
  },
  // [types.SAVE_ORDERING_ARRAY](state, payload) {
  //   state.orderingArray = payload
  // },
  clearData(state) {
    state.address = {
      administrative_area_level_1: '',
      country: 'Canada',
      latitude: 0.00,
      locality: '',
      longitude: -0.00,
      postal_code: '',
      route: '',
      street_number: ''
    }
    state.brokerages = []
    state.selectedAgent = null
    state.selectedBrokerage = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
