import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import {get, find} from 'lodash'

const Home = () =>
  import ( /* webpackChunkName: "Home" */ '../parts/Home.vue')
const HomepageSettings = () =>
  import ( /* webpackChunkName: "HomepageSettings" */ '../parts/HomepageSettings.vue')
const Themes = () =>
  import( /* webpackChunkName: "Themes" */ '../parts/Themes.vue')

const ResetPassword = () =>
  import ( /* webpackChunkName: "ResetPassword" */ '../parts/accounts/ResetPassword.vue')
const LoadingComp = () =>
  import ( /* webpackChunkName: "LoadingComp" */ '../parts/LoadingComp.vue')
const ErrorComp = () =>
  import ( /* webpackChunkName: "LoadingComp" */ '../parts/ErrorComp.vue')
const Catalog = () =>
  import ( /* webpackChunkName: "Catalog" */ '../parts/Catalog.vue')
const Services = () =>
  import ( /* webpackChunkName: "Services" */ '../parts/Services.vue')
const Packages = () =>
  import ( /* webpackChunkName: "Packages" */ '../parts/Packages.vue')
const ShoppingCart = () =>
  import ( /* webpackChunkName: "ShoppingCart" */ '../parts/ShoppingCart.vue')
const Checkout = () =>
  import ( /* webpackChunkName: "Checkout" */ '../parts/Checkout.vue')
const PagesBase = () =>
  import ( /* webpackChunkName: "PagesBase" */ '../parts/PagesBase.vue')
const OrderDetail = () =>
  import ( /* webpackChunkName: "OrderDetail" */ '../parts/orders/OrderDetail.vue')
const CompletedOrders = () =>
  import ( /* webpackChunkName: "CompletedOrders" */ '../parts/CompletedOrders.vue')
const OrdersSummary = () =>
  import ( /* webpackChunkName: "OrdersSummary" */ '../parts/orders/OrdersSummary.vue')

const ViewCategories = () =>
  import ( /* webpackChunkName: "ViewCategories" */ '../parts/categories/ViewCategories.vue')
const ViewSubcategories = () =>
  import ( /* webpackChunkName: "ViewSubcategories" */ '../parts/categories/ViewSubcategories.vue')

const ViewInventory = () =>
  import ( /* webpackChunkName: "ViewInventory" */ '../parts/inventory/ViewInventory.vue')
const InventoryCategories = () =>
  import ( /* webpackChunkName: "InventoryCategories" */ '../parts/inventory/InventoryCategories.vue')
const InventoryProducts = () =>
  import ( /* webpackChunkName: "InventoryProducts" */ '../parts/inventory/InventoryProducts.vue')
const InventoryProductsVariations = () =>
  import ( /* webpackChunkName: "InventoryProductsVariations" */ '../parts/inventory/InventoryProductsVariations.vue')
const InventoryItems = () =>
  import ( /* webpackChunkName: "InventoryItems" */ '../parts/inventory/InventoryItems.vue')

const InventoryLog = () =>
  import ( /* webpackChunkName: "InventoryLog" */ '../parts/inventory/InventoryLog.vue')
const AllInvoices = () =>
  import ( /* webpackChunkName: "AllInvoices" */ '../parts/billing/AllInvoices.vue')
const Invoices = () =>
  import ( /* webpackChunkName: "Invoices" */ '../parts/billing/Invoices.vue')
const ClientInvoices = () =>
  import ( /* webpackChunkName: "ClientInvoices" */ '../parts/billing/ClientInvoices.vue')
const InvoiceDetails = () =>
  import ( /* webpackChunkName: "InvoiceDetails" */ '../parts/billing/InvoiceDetails.vue')
const MonthInvoicesDetail = () =>
  import ( /* webpackChunkName: "MonthInvoicesDetail" */ '../parts/billing/MonthInvoicesDetail.vue')
const PaymentMethods = () =>
  import ( /* webpackChunkName: "PaymentMethods" */ '../parts/billing/PaymentMethods.vue')
const PlanDriveSheet = () =>
  import ( /* webpackChunkName: "PlanDriveSheet" */ '../parts/trips/PlanDriveSheet.vue')

const DriveSheetPackingList = () =>
  import ( /* webpackChunkName: "DriveSheetPackingList" */ '../parts/trips/DriveSheetPackingList.vue')

const DriveSheetDetail = () =>
  import ( /* webpackChunkName: "DriveSheetDetail" */ '../parts/trips/DriveSheetDetail.vue')
const Employees = () =>
  import ( /* webpackChunkName: "Employees" */ '../parts/employees/manage_employees/Employees.vue')
const Roles = () =>
  import( /* webpackChunkName: "Roles" */ '../parts/employees/manage_roles/Roles.vue')
const EmployeeDetails = () =>
  import ( /* webpackChunkName: "EmployeeDetails" */ '../parts/employees/manage_employees/EmployeeDetails.vue')
const ViewSuppliers = () =>
  import( /* webpackChunkName: "ViewSuppliers" */ '../parts/suppliers/ViewSuppliers.vue')

const SuppliersPage = () =>
  import( /* webpackChunkName: "SuppliersPage" */ '../parts/suppliers/SuppliersPage.vue')

const BrokeragePage = () =>
  import( /* webpackChunkName: "BrokeragePage" */ '../parts/brokerages/BrokeragePage.vue')

const SupplierDetails = () =>
  import ( /* webpackChunkName: "SupplierDetails" */ '../parts/suppliers/SupplierDetails.vue')

const ServiceDetails = () =>
  import ( /* webpackChunkName: "ServiceDetails" */ '../parts/suppliers/ServiceDetails.vue')
const AddonDetails = () =>
  import ( /* webpackChunkName: "AddonDetails" */ '../parts/suppliers/AddonDetails.vue')

const AllSuppliers = () =>
  import ( /* webpackChunkName: "AllSuppliers" */ '../parts/payments/AllSuppliers.vue')

const ViewPackages = () =>
import ( /* webpackChunkName: "ViewPackages" */ '../parts/packages/ViewPackages.vue')
const PackageDetails = () =>
import ( /* webpackChunkName: "PackageDetails" */ '../parts/packages/PackageDetails.vue')

const Profile = () =>
  import ( /* webpackChunkName: "Profile" */ '../parts/agents/Profile.vue')
const ViewAgents = () =>
  import ( /* webpackChunkName: "ViewAgents" */ '../parts/agents/ViewAgents.vue')
const AgentsOrders = () =>
  import ( /* webpackChunkName: "AgentsOrders" */ '../parts/agents/AgentsOrders.vue')

const Payments = () =>
  import ( /* webpackChunkName: "Payments" */ '../parts/payments/Payments.vue')
const Payouts = () =>
  import ( /* webpackChunkName: "Payouts" */ '../parts/payments/Payouts.vue')
const GrossSales = () =>
  import ( /* webpackChunkName: "GrossSales" */ '../parts/payments/GrossSales.vue')
const Deductions = () =>
  import ( /* webpackChunkName: "Deductions" */ '../parts/payments/Deductions.vue')

const StripeCardForm = () =>
  import ( /* webpackChunkName: "StripeCardForm" */ '../parts/multi_use_comp/stripe/StripeCardForm.vue')
const ConnectStripeAccount = () =>
  import ( /* webpackChunkName: "ConnectStripeAccount" */ '../parts/multi_use_comp/stripe/ConnectStripeAccount.vue')

const ViewBrokerages = () =>
  import ( /* webpackChunkName: "ViewBrokerages" */ '../parts/brokerages/ViewBrokerages.vue')
const BrokerageDetails = () =>
  import ( /* webpackChunkName: "BrokerageDetails" */ '../parts/brokerages/BrokerageDetails.vue')

const ListPosts = () =>
  import ( /* webpackChunkName: "ListPosts" */ '../parts/blog/ListPosts.vue')
const ViewPost = () =>
  import ( /* webpackChunkName: "ViewPost" */ '../parts/blog/ViewPost.vue')

const Contact = () =>
  import ( /* webpackChunkName: "Contact" */ '../parts/contact/Contact.vue')

const Financing = () =>
  import( /* webpackChunkName: "Contact" */ '../parts/financing/Financing.vue')

const PerksAndBenefits = () =>
  import( /* webpackChunkName: "Contact" */ '../parts/perksandbenefits/PerksAndBenefits.vue')

const About = () =>
  import ( /* webpackChunkName: "About" */ '../parts/About.vue')

const Privacy = () =>
  import ( /* webpackChunkName: "Privacy" */ '../parts/legal/Privacy.vue')
const TermsAndConditions = () =>
  import ( /* webpackChunkName: "TermsAndConditions" */ '../parts/legal/TermsAndConditions.vue')
const AcceptableUse = () =>
  import ( /* webpackChunkName: "AcceptableUse" */ '../parts/legal/AcceptableUse.vue')

import {
  ROLE_PAGE_ID_SUPPLIERS,
  ROLE_PAGE_ID_TEAM_MEMBERS,
  ROLE_PAGE_ID_BROKERAGES,
  ROLE_PAGE_ID_AGENTS,
  ROLE_PAGE_ID_SHOP,
  ROLE_PAGE_ID_ORDERS,
  ROLE_PAGE_ID_BILLING,
  ROLE_PAGE_ID_FINANCING,
  ROLE_PAGE_ID_TRIPS,
  ROLE_PAGE_ID_INVENTORY,
  ROLE_PAGE_ID_SETTINGS
} from '../constants'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/accounts',
      name: 'Accounts',
      component: PagesBase,
      children: [
        {
          path: '/reset_password/:token?',
          name: 'ResetPassword',
          component: ResetPassword,
          meta: {
            title: 'Reset Your Password'
          }
        }
      ]
    },
    {
      path: '/',
      name: 'Address',
      component: Home,
      children: [
        {
          path: '/homepage/supplier/:supplier_slug',
          name: 'SupplierHome',
          component: Home,
          meta: {
            title: 'Supplier Homepage'
          }
        },
        {
          path: '/homepage/brokerage/:brokerage_slug',
          name: 'BrokerageHome',
          component: Home,
          meta: {
            title: 'Brokerage Homepage'
          }
        },
      ]
    },
    {
      path: '/loading',
      name: 'Loading',
      component: LoadingComp
    },
    {
      path: '/card',
      name: 'Card',
      component: StripeCardForm
    },
    {
      path: '/error',
      name: 'Error',
      component: ErrorComp
    },
    {
      path: '/shopping/cart',
      name: 'ShoppingCart',
      component: ShoppingCart
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: Checkout
    },
    {
      path: '/settings',
      name: 'Settings',
      component: PagesBase,
      children: [{
          path: 'homepage',
          name: 'Homepage Settings',
          component: HomepageSettings,
          meta: {
            title: 'Homepage Settings',
            permission_id: ROLE_PAGE_ID_SETTINGS
          }
        },
        {
          path: 'themes',
          name: 'Themes',
          component: Themes,
          meta: {
            title: 'Themes',
            permission_id: ROLE_PAGE_ID_SETTINGS
          }
        },
      ]
    },
    {
      path: '/agents',
      name: 'Agents',
      component: PagesBase,
      children: [{
          path: '/my_profile/:id?',
          name: 'MyProfile',
          component: Profile,
          meta: {
            title: 'My Profile',
            permission_id: ROLE_PAGE_ID_AGENTS
          }
        },
        {
          path: '/all_agents',
          name: 'ViewAgents',
          component: ViewAgents,
          meta: {
            title: 'All Agents',
            permission_id: ROLE_PAGE_ID_AGENTS
          }
        },
        {
          path: '/show_agent_orders/:agent_id',
          name: 'AgentsOrders',
          component: AgentsOrders,
          meta: {
            title: 'Agent Orders',
            permission_id: ROLE_PAGE_ID_AGENTS
          }
        }
      ]
    },
    {
      path: '/brokerages',
      name: 'Brokerages',
      component: PagesBase,
      children: [
        {
          path: '/view_brokerages',
          name: 'ViewBrokerages',
          component: ViewBrokerages,
          meta: {
            title: 'View Brokerages',
            permission_id: ROLE_PAGE_ID_BROKERAGES
          }
        },
        {
          path: 'brokerage_details/:brokerage_id',
          name: 'BrokerageDetails',
          component: BrokerageDetails,
          meta: {
            title: 'Brokerage Details',
            permission_id: ROLE_PAGE_ID_BROKERAGES
          }
        }
      ]
    },
    {
      path: '/',
      name: 'ContactBase',
      component: PagesBase,
      children: [
        {
          path: '/contact',
          name: 'Contact',
          component: Contact,
          meta: {
            title: 'Contact Us'
          }
        },
      ]
    },
    {
      path: '/',
      name: 'FinancingBase',
      component: PagesBase,
      children: [
        {
          path: '/financing',
          name: 'Financing',
          component: Financing,
          meta: {
            title: 'Financing',
          }
        },
      ]
    },
    {
      path: '/',
      name: 'PerksAndBenefitsBase',
      component: PagesBase,
      children: [
        {
          path: '/perksandbenefits',
          name: 'PerksAndBenefits',
          component: PerksAndBenefits,
          meta: {
            title: 'PerksAndBenefits'
          }
        },
      ]
    },
    {
      path: '/',
      name: 'AboutBase',
      component: PagesBase,
      children: [
        {
          path: '/about',
          name: 'About',
          component: About,
          meta: {
            title: 'About Us'
          }
        },
      ]
    },
    {
      path: '/',
      name: 'Blog',
      component: PagesBase,
      children: [
        {
          path: '/blog',
          name: 'ListPosts',
          component: ListPosts,
          meta: {
            title: 'Blog Posts'
          }
        },
        {
          path: '/blog/:id',
          name: 'ViewPost',
          component: ViewPost,
          meta: {
            title: 'View Blog Post'
          }
        },
      ]
    },
    {
      path: '/payments',
      name: 'Payments',
      component: PagesBase,
      children: [
        // Duplicated on request
        {
          path: 'all_suppliers/',
          name: 'AllSuppliers',
          component: AllSuppliers,
          meta: {
            title: 'All Suppliers'
          }
        },
        {
          path: '/my_account/:supplier_user_id',
          name: 'PaymentsDetails',
          component: Payments,
          meta: {
            title: 'My Payouts'
          }
        },
        {
          path: '/my_account/gross_sales/:supplier_user_id',
          name: 'GrossSales',
          component: GrossSales,
          meta: {
            title: 'Gross Sales Details'
          }
        },
        {
          path: '/my_account/deductions/:supplier_user_id',
          name: 'Deductions',
          component: Deductions,
          meta: {
            title: 'Deductions Details'
          }
        },
        {
          path: '/my_account/payouts/:supplier_user_id',
          name: 'Payouts',
          component: Payouts,
          meta: {
            title: 'Payouts Details'
          }
        },
      ]
    },
    {
      path: '/orders',
      name: 'MyOrders',
      component: PagesBase,
      children: [{
          path: 'completed_order',
          name: 'CompletedOrders',
          component: CompletedOrders,
          meta: {
            title: 'My Orders',
            permission_id: ROLE_PAGE_ID_ORDERS
          }
        },
        {
          path: 'detail/:order_id',
          name: 'OrderDetail',
          component: OrderDetail,
          meta: {
            title: 'Order Detail',
            permission_id: ROLE_PAGE_ID_ORDERS
          }
        },
        {
          path: 'orders_summary',
          name: 'OrdersSummary',
          component: OrdersSummary,
          meta: {
            title: 'My Signs',
            permission_id: ROLE_PAGE_ID_ORDERS
          }
        }
      ]
    },
    {
      path: '/',
      component: PagesBase,
      children: [
        {
          path: 'connect_stripe_account',
          name: 'ConnectStripeAccount',
          component: ConnectStripeAccount,
          meta: {
            title: 'Connect Stripe Account'
          }
        }
      ]
    },
    // {
    //   path: '/',
    //   component: PagesBase,
    //   children: [
    //     {
    //       path: 'custom_ordering',
    //       name: 'CustomOrdering',
    //       component: CustomOrdering,
    //       meta: {
    //         title: 'Custom Ordering'
    //       }
    //     }
    //   ]
    // },
    {
      path: '/',
      name: 'MyInvoices',
      component: PagesBase,
      children: [{
          path: 'all_invoices',
          name: 'AllInvoices',
          component: AllInvoices,
          meta: {
            title: 'My Invoices'
          }
        },
        {
          path: '/invoices',
          name: 'Invoices',
          component: Invoices,
          meta: {
            title: 'Accounting Dashboard',
            permission_id: ROLE_PAGE_ID_BILLING
          }
        },
        {
          path: '/invoices/invoice_details',
          name: 'InvoiceDetails',
          component: InvoiceDetails,
          meta: {
            title: 'Invoice Details',
            permission_id: ROLE_PAGE_ID_BILLING
          }
        },
        {
          path: '/invoices/client_invoices',
          name: 'ClientInvoices',
          component: ClientInvoices,
          meta: {
            title: 'Client Invoices',
            permission_id: ROLE_PAGE_ID_BILLING
          }
        },
        {
          path: 'month_invoices_detail/:year/:month',
          name: 'MonthInvoicesDetail',
          component: MonthInvoicesDetail,
          meta: {
            title: 'Monthly invoices'
          }
        },
        {
          path: 'payment_methods',
          name: 'PaymentMethods',
          component: PaymentMethods,
          meta: {
            title: 'Payment Methods'
          }
        }
      ]
    },
    {
      path: '/trips',
      name: 'Trips',
      component: PagesBase,
      children: [{
          path: 'plan_drivesheet',
          name: 'PlanDriveSheet',
          component: PlanDriveSheet,
          meta: {
            title: 'Plan DriveSheet',
            permission_id: ROLE_PAGE_ID_TRIPS
          }
        },
        {
          path: 'drivesheet_detail/:drivesheet_id',
          name: 'DriveSheetDetail',
          component: DriveSheetDetail,
          meta: {
            title: 'DriveSheet Detail',
            permission_id: ROLE_PAGE_ID_TRIPS
          }
        },
        {
          path: 'drivesheet_packing_list/:drivesheet_id',
          name: 'DriveSheetPackingList',
          component: DriveSheetPackingList,
          meta: {
            title: 'DriveSheet Packing List',
            permission_id: ROLE_PAGE_ID_TRIPS
          }
        }
      ]
    },
    {
      path: '/inventory',
      name: 'Inventory',
      component: PagesBase,
      children: [{
          path: 'view_inventory',
          name: 'ViewInventory',
          component: ViewInventory,
          meta: {
            title: 'View Inventory',
            permission_id: ROLE_PAGE_ID_INVENTORY
          }
        },
        {
          path: 'view_inventory_log/:inventory_id',
          name: 'InventoryLog',
          component: InventoryLog,
          meta: {
            title: 'Inventory Log',
            permission_id: ROLE_PAGE_ID_INVENTORY
          }
        },
        {
          path: 'view_inventory_categories',
          name: 'InventoryCategories',
          component: InventoryCategories,
          meta: {
            title: 'Inventory Categories',
            permission_id: ROLE_PAGE_ID_INVENTORY
          }
        },
        {
          path: 'view_inventory_products/:category_id',
          name: 'InventoryProducts',
          component: InventoryProducts,
          meta: {
            title: 'Inventory Products',
            permission_id: ROLE_PAGE_ID_INVENTORY
          }
        },
        {
          path: 'products_variations/:inventory_product_id',
          name: 'InventoryProductsVariations',
          component: InventoryProductsVariations,
          meta: {
            title: 'Inventory Products Variations',
            permission_id: ROLE_PAGE_ID_INVENTORY
          }
        },
        {
          path: 'inventory_items/:products_variation_id',
          name: 'InventoryItems',
          component: InventoryItems,
          meta: {
            title: 'Inventory Items',
            permission_id: ROLE_PAGE_ID_INVENTORY
          }
        }
      ]
    },
    {
      path: '/categories',
      name: 'Categories',
      component: PagesBase,
      children: [{
          path: 'view_categories',
          name: 'ViewCategories',
          component: ViewCategories,
          meta: {
            title: 'Main Categories'
          }
        },
        {
          path: 'view_categories/:category_id/subcategories',
          name: 'ViewSubcategories',
          component: ViewSubcategories,
          meta: {
            title: 'View Subcategories'
          }
        },
      ]
    },
    {
      path: '/packages',
      name: 'Packages',
      component: PagesBase,
      children: [
        {
          path: 'view_packages',
          name: 'ViewPackages',
          component: ViewPackages,
          meta: {
            title: 'View Packages'
          }
        },
        {
          path: 'package_details/:package_id',
          name: 'PackageDetails',
          component: PackageDetails,
          meta: {
            title: 'Package Details'
          }
        },
      ]
    },
    {
      path: '/',
      name: 'Legal',
      component: PagesBase,
      children: [
        {
          path: 'privacy',
          name: 'Privacy',
          component: Privacy,
          meta: {
            title: 'Privacy Policy'
          }
        },
        {
          path: 'acceptable_use',
          name: 'AcceptableUse',
          component: AcceptableUse,
          meta: {
            title: 'Acceptable Use'
          }
        },
        {
          path: 'terms_and_conditions',
          name: 'TermsAndConditions',
          component: TermsAndConditions,
          meta: {
            title: 'Terms and Conditions'
          }
        },
      ]
    },
    {
      path: '/employees',
      name: 'Employees',
      component: PagesBase,
      children: [{
          path: 'view_employees',
          name: 'ViewEmployees',
          component: Employees,
          meta: {
            title: 'Team Members List',
            permission_id: ROLE_PAGE_ID_TEAM_MEMBERS
          }
        },
        {
          path: 'employee_details/:employee_id',
          name: 'EmployeeDetails',
          component: EmployeeDetails,
          meta: {
            title: 'Team Member details',
            permission_id: ROLE_PAGE_ID_TEAM_MEMBERS
          }
        },
        {
          path: 'roles',
          name: 'EmployeeRoles',
          component: Roles,
          meta: {
            title: 'Roles',
            permission_id: ROLE_PAGE_ID_TEAM_MEMBERS
          }
        }
      ]
    },
    {
      path: '/catalog',
      name: 'Catalog',
      component: Catalog,
      children: [
        {
          path: 'services',
          name: 'ServicesCatalog',
          component: Services
        },
        {
          path: 'services/brokerage/:brokerage_slug',
          name: 'ServicesCatalog',
          component: Services
        },
        {
          path: 'services/supplier/:supplier_slug',
          name: 'ServicesCatalog',
          component: Services
        },

        {
          path: 'packages',
          name: 'PackagesCatalog',
          component: Packages
        },
        {
          path: 'packages/brokerage/:brokerage_slug',
          name: 'PackagesCatalog',
          component: Packages
        },
        {
          path: 'packages/supplier/:supplier_slug',
          name: 'PackagesCatalog',
          component: Packages
        },
      ]
    },
    {
      path: '/suppliers',
      name: 'Suppliers',
      component: PagesBase,
      children: [{
          path: ':type',
          name: 'ViewSuppliers',
          component: ViewSuppliers,
          meta: {
            title: 'View Suppliers',
            permission_id: ROLE_PAGE_ID_SUPPLIERS
          }
        },
        {
          path: 'supplier_details/:supplier_user_id',
          name: 'SupplierDetails',
          component: SupplierDetails,
          meta: {
            permission_id: ROLE_PAGE_ID_SUPPLIERS
          }
        },
        {
          path: 'service_details/:service_id',
          name: 'ServiceDetails',
          component: ServiceDetails,
          meta: {
            permission_id: ROLE_PAGE_ID_SUPPLIERS
          }
        },
        {
          path: 'addon_details/:addon_id',
          name: 'AddonDetails',
          component: AddonDetails,
          meta: {
            permission_id: ROLE_PAGE_ID_SUPPLIERS
          }
        },
      ]
    },
    {
      path: '/:supplier_slug',
      name: 'SuppliersPage',
      component: Home,
      meta: {
        title: 'Suppliers Page',
        permission_id: ROLE_PAGE_ID_SUPPLIERS
      }
    },
    {
      path: '/brokerages/:brokerage_slug',
      name: 'BrokeragePage',
      component: Home,
      meta: {
        title: 'Brokerage Page'
      }
    },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

// Navigation guard to set page title automatically

router.beforeEach((to, from, next) => {
  store.dispatch('savePageTitle', to.meta.title)

  // Check for employee permission for current route
  const userRole = store.state.accounts.name.role

  if (store.state.accounts.name.user_type === 'employeeuser' && !userRole) {
    // Redirect user to homepage if they are an employeeuser and arre not assigned a role yet.
    next('/')
  } else {
    if (userRole) {
      const permissions = get(userRole, 'permissions', null);
      const toPermId = get(to, 'meta.permission_id', null)

      if (toPermId) {
        const userPermForTo = find(permissions, perm => get(perm, 'id', null) === toPermId)
        if (userPermForTo && userPermForTo.value === false || !permissions) {
          // Redirect employee back to Home, because they don't have permission to view this page
          next('/')
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      next()
    }
  }

})

export default router
