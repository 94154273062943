export function currency(value, currency, decimals) {
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD'
  }).format(value)
}
// Just to seprate the gst, so that later we can fectch the gst depending on user.
export function calculate_gst(value, type) {
  if (type == 'Add') {
    return value * 1.05
  } else {
    return value * .05
  }

}
