import * as types from '../mutation-types'
import Vue from 'vue'
import {
  API_V1_ROOT
} from '../../config'

const state = {
  my_orders: [],
  ordersLoaded: false,
  attachmentDialog: false,
  orderStatusDialog: false,
  attachmentDialogItem: {},
  agentAttachmentDialogItem: false,
}

const getters = {
  my_orders: state => state.my_orders,
  ordersLoaded: state => state.ordersLoaded,
  getAttachmentDialog: state => state.attachmentDialog,
  getorderStatusDialog: state => state.orderStatusDialog,
  attachmentDialogItem: state => state.attachmentDialogItem,
  agentAttachmentDialogItem: state => state.agentAttachmentDialogItem
}

const actions = {
  toggleAttachmentDialog({
    commit,
    getters
  }, payload) {
    commit(types.ATTACHMENT_DIALOG, payload)
  },
  toggleOrderStatusDialog({
    commit,
    getters
  }, payload) {
    commit(types.ORDER_STATUS_DIALOG, payload)
  },
  saveAttachmentDialogItem({
    commit,
    getters
  }, payload) {
    commit(types.ATTACHMENT_DIALOG_ITEM, payload)
  },
  toggleAgentAttachmentDialog({
    commit,
    getters
  }, payload) {
    commit(types.AGENT_ATTACHMENT_DIALOG, payload)
  },
  getMyOrders({
    commit,
    state,
    getters
  }, payload) {
    const options = {
      params: {},
    }
    if (payload && payload.year && payload.month) {
      Object.assign(options.params, {
        order_year: payload.year,
        order_month: payload.month
      })
    }

    Vue.http
      .get(`${API_V1_ROOT}get_my_orders/`, options)
      .then((response) => {
        commit(types.SAVE_MY_ORDERS_DATA, response.data)
        commit('SAVE_ORDERS_LOADED', true)
      })
      .catch((error) => {
        console.log('Something wrong fetching orders. ')
      })
  }
}

const mutations = {
  [types.SAVE_MY_ORDERS_DATA](state, payload) {
    state.my_orders = payload
  },
  [types.ATTACHMENT_DIALOG](state, payload) {
    state.attachmentDialog = payload
  },
  [types.ORDER_STATUS_DIALOG](state, payload) {
    state.orderStatusDialog = payload
  },
  [types.ATTACHMENT_DIALOG_ITEM](state, payload) {
    state.attachmentDialogItem = payload
  },
  [types.AGENT_ATTACHMENT_DIALOG](state, payload) {
    state.agentAttachmentDialogItem = payload
  },
  SAVE_ORDERS_LOADED(state, payload) {
    state.ordersLoaded = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
