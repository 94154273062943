import * as types from '../mutation-types'

const state = {
    photoDialog: false,
    photoIndex: null,
}

const getters = {
    getPhotoDialog: state => state.photoDialog,
    getPhotoIndex: state => state.photoIndex,
}

const actions = {
    setPhotoDialog({ commit }, value) {
        commit(types.SET_DIALOG, value)
    },
    setPhotoIndex({ commit }, value) {
        commit(types.SET_INDEX, value)
    },
}

const mutations = {
    [types.SET_DIALOG](state, value) {
        state.photoDialog = value;
    },
    [types.SET_INDEX](state, value) {
        state.photoIndex = value;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}  

