import axios from 'axios';
import 'babel-polyfill';
import moment from 'moment-timezone';
import { VLazyImagePlugin } from "v-lazy-image";
import Vue from 'vue';
import VueMoment from 'vue-moment';
import Vuetify from 'vuetify';
import colors from 'vuetify/es5/util/colors';
import App from './App.vue';
import { API_V1_ROOT } from './config';
import { calculate_gst, currency } from './currency';
import router from './router';
import store from './store';
import { roundNumber, truncate } from './utilities';
import wysiwyg from 'vue-wysiwyg';
import * as VueGoogleMaps from 'vue2-google-maps'


import 'vue-wysiwyg/dist/vueWysiwyg.css';

moment.tz.setDefault('America/Edmonton')

Vue.use(VLazyImagePlugin);

Vue.use(VueMoment, {
  moment,
})

Vue.use(Vuetify, {
  theme: {
    primary: colors.shades.black,
    secondary: colors.red.lighten4,
    accent: colors.pink.base
  }
})

Vue.use(wysiwyg, {
  hideModules: {
    'image': true,
    'table': true,
    'code': true,
    'orderedList': true,
    'unorderedList': true,
    'link': true,
    'headings': true,
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyALTyBW0aPXPDyBSWGx1iWYPR4pP05aNfg',
    libraries: 'places',
  },
})

//AXIOS SETTINGS
Vue.prototype.$http = axios
Vue.http = axios
const noInterceptAxios = axios.create()

//add token with interceptor
Vue.http.interceptors.request.use(function (config) {
  if (config.before) config.before() // making the before option work in axios...
  let tokenData = JSON.parse(localStorage.getItem('token'))
  if (!config.headers) {
    config.headers = {}
  }
  let auth = config.headers['Authorization']
  if (tokenData && !auth) {
    config.headers['Authorization'] = `Bearer ${tokenData.token}`
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

//use refresh token to get new token
function updateToken() {
  const updateToken = new Promise(function (resolve, reject) {
    let tokenData = JSON.parse(localStorage.getItem('token'))
    if (tokenData) {
      let refresh_token = tokenData._token
      let formData = new FormData();
      formData.append('grant_type', 'refresh_token')
      formData.append('refresh_token', refresh_token)
      const options = {
        headers: {
          'Authorization': `Basic ${window.meta_data}`
        },
      }
      noInterceptAxios.post(`${API_V1_ROOT}token/`, formData, options).then(
        (response) => {
          const token = response.data.access_token
          if (token) {
            const token_data = {
              token: response.data.access_token,
              _token: response.data.refresh_token,
            }
            localStorage.setItem("token", JSON.stringify(token_data))
            store.dispatch('saveToken', token_data)
            resolve(token)
          } else {
            reject(Error('No token in response'))
          }
        }, (error) => {
          reject(error)
        }
      )
    } else {
      reject(Error('No token data'))
    }
  })
  return updateToken
}

//use interceptor to retry request with new token
Vue.http.interceptors.response.use(null, (error) => {
  if (error.config && error.response && error.response.status === 403) {
    return updateToken().then((token) => {
      error.config.headers.Authorization = `Bearer ${token}`
      return noInterceptAxios.request(error.config);
    }, (error) => {
      // store.commit('LOGIN_DIALOG', true)
      // return Promise.reject(error);
    });
  } else {
    return Promise.reject(error);
  }
});
//END AXIOS SETTINGS


Vue.filter('currency', currency)
Vue.filter('gst', calculate_gst)
Vue.filter('truncate', truncate)
Vue.filter('roundNumber', roundNumber)


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
