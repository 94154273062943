import * as types from '../mutation-types'
import Vue from 'vue'
import {
  API_V1_ROOT
} from '../../config'

const state = {
  show_options_dialog: false,
  next_counter: 1,
  show_info_dialog: false,
  show_sample_dialog: {
    item_id: 0,
    visibility: false
  },
  current_card: {},
  next_cards: [],
  current_package: {},
  current_samples: [],
  show_success_msg_on_option_close: false,
  success_msg_on_option_close: null,
}

const getters = {
  optionsDialogVisibility: state => state.show_options_dialog,
  infoDialogVisibility: state => state.show_info_dialog,
  sampleDialogVisibility: state => state.show_sample_dialog.visibility,
  sampleDialogItemId: state => state.show_sample_dialog.item_id,
  getCurrentSamples: state => state.current_samples,
  getCurrentCard: state => state.current_card,
  getNextCard: state => state.next_cards,
  getCurrentPackage: state => state.current_package,
  getTotalservices_included: state => state.current_package.services_included,
  getShowSuccessMsgOnOptionClose: state => state.show_success_msg_on_option_close,
  getSuccessMsgOnOptionClose: state => state.success_msg_on_option_close,
}

const actions = {
  toggleOptionsDialog({
    commit,
    getters,
    dispatch
  }, payload) {
    commit(types.OPTIONS_DIALOG_VISIBILITY, payload)
    dispatch('toggleDocScroll', payload)
  },
  toggleInfoDialog({
    commit,
    getters
  }, payload) {
    commit(types.INFO_DIALOG_VISIBILITY, payload)
  },
  toggleSampleDialog({
    commit,
    getters,
    state
  }, payload) {
    if (payload.item_id !== state.show_options_dialog.item_id) {
      Vue.http.get(`${API_V1_ROOT}show_samples/${payload.item_id}/`)
        .then((response) => {
          commit(types.CURRENT_SAMPLES, response.data)
        })
    }
    commit(types.SAMPLE_DIALOG_VISIBILITY, payload)
  },
  setCurrentCard({
    commit
  }, payload) {
    commit(types.CURRENT_CARD, payload)
  },
  setNextCard({
    commit
  }, payload) {
    commit(types.NEXT_CARDS, payload)
  },
  setCurrentPackage({
    commit
  }, payload) {
    commit(types.CURRENT_PACKAGE, payload)
  },
  setCurrentCardPackages({
    commit,
    getters,
    dispatch
  }, payload) {
    if (typeof payload.index === "number") { //if they click on a service that's part of a package, we go directly to that service by providing an index
      var services_included = payload.package.services_included
      var card_with_type = services_included[payload.index]
      if (payload.package) {
        card_with_type.cardType = payload.package.cardType
      }
      var pkg = payload.package
      const next_counter = payload.index+1
      commit(types.SET_NEXT_COUNTER, next_counter)
    } else {
      var services_included = payload.services_included
      var card_with_type = services_included[0]
      card_with_type.cardType = payload.cardType
      var pkg = payload
    }
    commit(types.OPTIONS_DIALOG_VISIBILITY, true)
    dispatch('setCurrentCard', card_with_type)
    dispatch('setNextCard', services_included)
    dispatch('setCurrentPackage', pkg)
  },
  toggleDocScroll({}, payload) {
    /*
     Disables scrolling when dialog box is opened by settign the css property `overflowY` in the `html` element.
     Removes the css property when the dialog is closed.
     */
    if (payload) {
      document.getElementsByTagName("html")[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName("html")[0].style.overflowY = '';
    }
  },
  clearOptionsDialog({
    commit,
    getters,
    dispatch
  }, payload) {
    dispatch('setCurrentCard', {})
    dispatch('setNextCard', [])
    dispatch('setCurrentPackage', {})
  },
  toggleShowSuccessMsgOnOptionClose({
    commit
  }, payload) {
    commit(types.SHOW_SUCCESS_MSG_ON_OPTION_CLOSE, payload)
  },
  toggleSuccessMsgOnOptionClose({
    commit
  }, payload) {
    commit(types.SUCCESS_MSG_ON_OPTION_CLOSE, payload)
  }
}

const mutations = {
  [types.OPTIONS_DIALOG_VISIBILITY](state, payload) {
    state.show_options_dialog = payload
  },
  [types.SET_NEXT_COUNTER](state, payload) {
    state.next_counter = payload
  },
  [types.INFO_DIALOG_VISIBILITY](state, payload) {
    state.show_info_dialog = payload
  },
  [types.SAMPLE_DIALOG_VISIBILITY](state, payload) {
    state.show_sample_dialog = payload
  },
  [types.CURRENT_CARD](state, payload) {
    state.current_card = payload
  },
  [types.NEXT_CARDS](state, payload) {
    state.next_cards = payload
  },
  [types.CURRENT_PACKAGE](state, payload) {
    state.current_package = payload
  },
  [types.CURRENT_SAMPLES](state, payload) {
    state.current_samples = payload
  },
  [types.SHOW_SUCCESS_MSG_ON_OPTION_CLOSE](state, payload) {
    state.show_success_msg_on_option_close = payload
  },
  [types.SUCCESS_MSG_ON_OPTION_CLOSE](state, payload) {
    state.success_msg_on_option_close = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
